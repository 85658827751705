<template>
  <div class="storeInfo" style="text-align: left;">
    <van-nav-bar
        title="门店信息"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <br />
    <van-form @submit="onSubmit">
      <van-field
          readonly
          clickable
          class="areaClass"
          name="area"
          :value="areaStr"
          label="省市区域"
      />
      <van-field
          v-model="address"
          name="address"
          rows="2"
          autosize
          label="详细地址"
          type="textarea"
          maxlength="64"
          placeholder="请填写详细地址"
          show-word-limit
      />
      <van-field v-model="storeName" clearable name="storeName" label="门店名称" placeholder="请输入门店名称" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交修改</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {getAreaList, getStoreInfo, updateShopInfo} from "@/api/login";
import {Toast} from "vant";

export default {
  name: "StoreInfo",
  components: {},
  data() {
    return {
      areaStr: '',
      areaArrVal: [],
      address: '',
      storeName: '',
      areaList: [],
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.areaList = getAreaList();
      getStoreInfo().then(r => {
        console.log(r);
        let province_list = this.areaList.province_list;
        let city_list = this.areaList.city_list;
        let county_list = this.areaList.county_list;
        let provinceName = province_list[r.data.provinceCode];
        let cityName = city_list[r.data.cityCode];
        let regionName = county_list[r.data.regionCode];
        if(cityName.startsWith(provinceName)) {
          this.areaStr = cityName + '/' + regionName;
        } else {
          this.areaStr = provinceName + '/' + cityName + '/' + regionName;
        }
        this.address = r.data.address;
        this.storeName=r.data.storeName;
      });
    },
    onSubmit() {
      console.log(this.storeName);
      if(!this.storeName) {
        Toast.fail("门店名称不能为空.")
      } else if(!this.address) {
        Toast.fail("门店详细地址不能为空.")
      } else {
        updateShopInfo({address: this.address, storeName: this.storeName}).then(r => {
          console.log(r);
          Toast.success("操作成功!")
        });
      }
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
